import { Dashboard } from "@/components/Dashboard";
import { Shimmer } from "@/components/Shimmer";
import { WorkspaceDetails } from "@/components/WorkspaceDetails";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Ta } from "@/components/ui/icons";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useState } from "react";
import { useCurrentOrg } from "./$org._layout";

// Define the index route for the workspace layout
export const Route = createFileRoute(
  "/$org/_layout/workspace/$workspace/_layout/",
)({
  component: WorkspaceIndex,
});

function WorkspaceIndex() {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const navigate = useNavigate();
  // this usage of localstorage seems terrible:
  const space = localStorage.getItem("currentWorkspaceID");
  const { org } = useCurrentOrg();

  if (!space) {
    return <div $>No workspace found</div>;
  }

  return (
    <div $="flex justify-between mt-3 border border-[#D4D4D8] rounded-md p-5 m-3 min-w-[800px] text-black">
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild $></DialogTrigger>
        <DialogContent $="bg-background">
          <DialogHeader $>
            <DialogTitle $="text-[#0F172A] text-[18px] font-semibold">
              Setup your workspace
            </DialogTitle>
            <DialogDescription $>
              <div $="mt-4">
                <p $="text-black text-base font-normal">
                  To get started create a new model or import a model from your
                  library.
                </p>
              </div>
              <div $="flex justify-end mt-6">
                <Button $="bg-white text-[#0F172A] hover:bg-white hover:text-[#0F172A] flex justify-center border border-[#D4D4D8] rounded-full ">
                  <Ta.IconBooks $="w-[20px] h-[20px]" />
                  Import Modules
                </Button>
                <Button
                  $="ml-2 bg-[#0F172A] text-white rounded-full flex justify-center hover:bg-[#0F172A] hover:text-white"
                  onClick={() =>
                    navigate({
                      to: "/$org/workspace/$workspace/model",
                      params: { org: org.urlKey, workspace: space },
                    })
                  }
                >
                  Create New Model
                </Button>
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
      <div $="w-[60%]">
        <Dashboard />
        <Shimmer />
      </div>
      <div $="w-[38%]">
        <WorkspaceDetails />
      </div>
    </div>
  );
}
