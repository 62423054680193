import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ProvideIcons, Ta } from "@/components/ui/icons";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ModalProvider } from "@/context/ModalContext";
import { ProvideBearerToken } from "@/contexts/BearerTokenContext";
import { WhoAmI } from "@/lib/dev/WhoAmI";
import { withAppDevtools } from "@/lib/dev/withAppDevtools";
import type { InternalError } from "@phosphor/server";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Outlet, createRootRoute } from "@tanstack/react-router";
import { useMemo } from "react";
import { ProvideCurrentUser } from "../contexts/CurrentUserContext";
import { ProvideNotifications } from "../hooks/notifications";

declare module "react" {
  interface DOMAttributes<T> {
    /**
     * Short-hand for `className`, and enhanced by tooling to include a data-loc attribute in development
     *
     * Whereever you see this is required, you know that
     * the end result links to an element in the DOM, which is pretty cool!
     */
    $: boolean | string;
    /** Prefer `$="classnames"` */
    className?: string | undefined;
  }
}

// Why Tanstack Router looks like this: https://tanstack.com/router/latest/docs/framework/react/decisions-on-dx
export const Route = createRootRoute({
  // supports dark through class
  component: () => {
    const pr = BUILD_DEPLOY_INFO.relatedPullRequest;
    const sentry = BUILD_DEPLOY_INFO.relatedSentry;
    return appProviders(
      withAppDevtools(
        <>
          {pr && (
            <div $="flex items-center justify-stretch p-2 gap-2 bg-lime-300 text-lime-900">
              <a $="grow font-semibold" href={pr.reviewURL}>
                {pr.title}
              </a>
              <span $="flex flex-0 items-center gap-1">
                <img $="flex-0 w-6 h-6 rounded" src={pr.authorAvatarURL} />
                <span $="flex-0 text-sm">{pr.authorName}</span>
              </span>
              <Popover>
                <PopoverTrigger $>
                  <Ta.IconUser />
                </PopoverTrigger>
                <PopoverContent $="w-[400px]">
                  <WhoAmI />
                </PopoverContent>
              </Popover>
              <DropdownMenu>
                <DropdownMenuTrigger $="px-2 py-1 rounded">
                  <Ta.IconLink />
                </DropdownMenuTrigger>
                <DropdownMenuContent $="bg-white border rounded shadow-lg">
                  {sentry && (
                    <>
                      <DropdownMenuLabel $="flex-0 flex items-center gap-2">
                        Sentry Links
                      </DropdownMenuLabel>
                      <DropdownMenuItem $>
                        <a
                          // Example: https://phosphor-co.sentry.io/issues/?environment=cole-gen-65-login-create-workspa&project=4507640788221952&statsPeriod=14d
                          href={`https://${sentry.server.orgSlug}.sentry.io/issues/?environment=${sentry.environment}&project=${sentry.server.projectID}&statsPeriod=14d`}
                          target="_blank"
                          rel="noopener noreferrer"
                          $="flex items-center gap-2"
                        >
                          <Ta.IconBrandSentry $="w-4 h-4" /> Server Issues
                        </a>
                      </DropdownMenuItem>
                      <DropdownMenuItem $>
                        <a
                          // Example: https://phosphor-co.sentry.io/issues/?environment=cole-gen-65-login-create-workspa&project=4507640788221952&statsPeriod=14d
                          href={`https://${sentry.server.orgSlug}.sentry.io/issues/?environment=${sentry.environment}&project=${sentry.editorWebui.projectID}&statsPeriod=14d`}
                          target="_blank"
                          rel="noopener noreferrer"
                          $="flex items-center gap-2"
                        >
                          <Ta.IconBrandSentry $="w-4 h-4" /> Editor Issues
                        </a>
                      </DropdownMenuItem>
                    </>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          )}
          <Outlet />
        </>,
      ),
    );
  },
});

/** WIP */
const useNotifications = () => {
  return useMemo(
    () => ({
      reportInternalError: (when: string, error: InternalError) => {
        console.error("Internal Error", error);
        const shouldReport = confirm(
          `Error ${when}\nWould you mind reporting the following details for us?\n\nreference: ${error.referenceID}\nenvironment: ${error.environment}`,
        );
        if (shouldReport) {
          window.open(
            "mailto:oliver@phosphor.co?subject=Internal%20Error%20Report&body=" +
              encodeURIComponent(
                `Reference ID: ${error.referenceID}\nEnvironment: ${error.environment}`,
              ),
            "_blank",
          );
        }
      },
    }),
    [],
  );
};

function appProviders(element: React.ReactElement) {
  const queryClient = useMemo(() => new QueryClient(), []);
  return (
    <ProvideNotifications>
      <QueryClientProvider client={queryClient}>
        <ProvideIcons>
          <ProvideCurrentUser>
            <ModalProvider>
              <ProvideBearerToken>{element}</ProvideBearerToken>
            </ModalProvider>
          </ProvideCurrentUser>
        </ProvideIcons>
      </QueryClientProvider>
    </ProvideNotifications>
  );
}
