import { Outlet, createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/$org/_layout/workspace/$workspace/_layout",
)({
  component: WorkspaceLayout,
});

function WorkspaceLayout() {
  return <Outlet />;
}
