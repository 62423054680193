import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Ta } from "@/components/ui/icons";
import { useModal } from "@/context/ModalContext";
import { useRpcMutation, useRpcQuery } from "@/hooks/use-rpc-hooks";
import { useCurrentOrg } from "@/routes/$org._layout";
import { FolderAPI, OrgFolderID, WorkspaceAPI } from "@phosphor/server";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { AppFailureCard } from "./AppFailureDisplay";
import { WorkspaceList } from "./WorkspaceList";
import { Badge } from "./ui/badge";
import { Button } from "./ui/button";
import { Input } from "./ui/input";

export const Home = () => {
  const { org, user } = useCurrentOrg();
  const navigate = useNavigate();
  const { isOpen, openModal, closeModal } = useModal();
  const [selectedFolder, setSelectedFolder] = useState<
    OrgFolderID | undefined
  >();

  const { data: folders } = useRpcQuery({
    queryKey: ["getFolders", org.id],
    request: new FolderAPI.GetOrgFolders({
      org: org.id,
    }),
  });

  useEffect(() => {
    if (folders && folders[0]?.folder_id) {
      setSelectedFolder(folders[0]?.folder_id);
    }
  }, [folders]);

  const [newWorkspace, setNewWorkspace] = useState<string>("");
  const queryClient = useQueryClient();
  const createWorkspaceMutation = useRpcMutation({
    mutate: (input: WorkspaceAPI.CreateWorkspace) => input,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["getWorkspaces", selectedFolder],
      });
      localStorage.setItem("currentWorkspaceID", data.workspace_id);
      localStorage.setItem("currentWorkspaceName", data.workspace_display_name);
      navigate({
        to: "/$org/workspace/$workspace",
        params: { org: org.urlKey, workspace: data.workspace_id },
      });
      closeModal();
    },
    onError: (error) => {
      console.error("Failed to create organization:", error);
    },
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewWorkspace(event.target.value);
  };

  const handleCreateWorkspace = () => {
    createWorkspaceMutation.mutate(
      new WorkspaceAPI.CreateWorkspace({
        displayName: newWorkspace,
        folder: selectedFolder,
      }),
    );
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleCreateWorkspace();
    }
  };

  const showFolders = folders && folders.length > 1;

  return (
    <>
      <div $="min-w-[600px] max-w-[1440px] mx-auto">
        <div $="flex justify-between p-5 gap-4">
          <QuickAccessSection />
          <ActivitySection />
        </div>
        <div $="flex flex-col gap-2 items-center mt-4">
          <p
            $="text-[#766293F5] text-[14px] tracking-widest font-medium cursor-pointer"
            onClick={openModal}
          >
            WORKSPACES <span $>(Create New)</span>
          </p>
          {showFolders && (
            <div $="flex gap-2">
              {folders?.map((folder) => (
                <Badge
                  $="cursor-pointer"
                  key={folder.folder_id}
                  variant={
                    selectedFolder === folder.folder_id ? "default" : "outline"
                  }
                  onClick={() => setSelectedFolder(folder.folder_id)}
                >
                  {folder.folder_display_name}
                </Badge>
              ))}
            </div>
          )}
        </div>
        <WorkspaceList
          folder={selectedFolder}
          orgID={org.id}
          orgURLKey={org.urlKey}
        />
        <Dialog open={isOpen} onOpenChange={(open) => !open && closeModal()}>
          <DialogTrigger $ asChild></DialogTrigger>
          <DialogContent $="bg-background p-4">
            <DialogHeader $>
              <DialogTitle $="text-[#0F172A] text-[18px] font-semibold">
                Create New Workspace
              </DialogTitle>
            </DialogHeader>
            <Input
              value={newWorkspace}
              onChange={handleInputChange}
              onKeyUp={handleKeyUp}
              $="bg-background w-full"
              placeholder="Enter workspace name"
            />
            {createWorkspaceMutation.error && (
              <AppFailureCard $ error={createWorkspaceMutation.error} />
            )}
            <div $="flex justify-end gap-2 mt-6">
              <Button
                $="bg-white text-[#0F172A] hover:bg-gray-100"
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                $="bg-[#0F172A] text-white rounded-full hover:bg-[#0F172A]"
                onClick={handleCreateWorkspace}
              >
                Continue
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

const QuickAccessSection = () => (
  <div $="flex-grow h-[316px] border border-[#D4D4D8] rounded-sm">
    <div $="h-[36px] border-b border-[#D4D4D8] flex items-center">
      <p $="text-[#766293F5] text-[14px] tracking-widest font-medium pl-3">
        QUICK ACCESS
      </p>
    </div>
    <div $="h-[88%] bg-background flex items-center pl-[42px] pr-2 text-base font-normal text-black">
      <div $="border border-[#766D87] rounded-full p-[8px] mr-[26px]">
        <Ta.IconPin $="w-[40px] h-[40px]" color="#766D87" />
      </div>
      <p $="text-base font-normal text-black min-w-[180px]">
        As you join several workspaces, you can pin those your frequent for
        quick access.
      </p>
    </div>
  </div>
);

const ActivitySection = () => (
  <div $="flex-grow h-[316px] border border-[#D4D4D8] rounded-sm">
    <div $="h-[36px] border-b border-[#D4D4D8] flex items-center justify-between">
      <p $="text-[#766293F5] text-[14px] tracking-widest font-medium pl-3">
        ACTIVITY
      </p>
      <p $="text-[#766293F5] text-[12px] pr-3">No new updates</p>
    </div>
    <div $="h-[88%] bg-background flex items-center pl-[42px] pr-2 text-base font-normal text-black">
      <div $="border border-[#766D87] rounded-full p-[8px] mr-[26px]">
        <Ta.IconActivity $="w-[40px] h-[40px]" color="#766D87" />
      </div>
      <p $="min-w-[180px] text-base font-normal text-black">
        Any updates you or collaborates make to Workspaces you participate will
        show in your activity feed.
      </p>
    </div>
  </div>
);
