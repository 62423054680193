import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";
import { useGroceryList } from "@/contexts/GroceryListContext";
import { useRpcMutation } from "@/hooks/use-rpc-hooks";
import { useCurrentOrg } from "@/routes/$org._layout";
import {
  type CommentTsID,
  GroceryListProposalAPI,
  type GroceryListProposalID,
} from "@phosphor/server";
import type React from "react";
import { useRef, useState } from "react";
import { RenderChangeIcon, formatChange } from "./GroceryListChangeUtils";

interface GLProposalCommentFormProps {
  proposalId: GroceryListProposalID;
  onRefetch: () => void;
  existingComment?: {
    ts: CommentTsID;
    content: string;
  };
  onCancelEdit?: () => void;
}

export const GLProposalCommentForm: React.FC<GLProposalCommentFormProps> = ({
  proposalId,
  onRefetch,
  existingComment,
  onCancelEdit,
}) => {
  const { org } = useCurrentOrg();
  const { currentState, originalItems, dev, clearChanges } = useGroceryList();
  const [newComment, setNewComment] = useState(existingComment?.content || "");
  const formRef = useRef<HTMLFormElement>(null);

  const createCommentMutation = useRpcMutation({
    orgID: org.id,
    mutate: (input: GroceryListProposalAPI.CreateComment) => input,
    onSuccess: () => {
      toast({
        title: "Comment Added",
        description: "Your comment has been added successfully.",
      });
      setNewComment("");
      clearChanges();
      onRefetch();
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to add the comment. Please try again.",
        variant: "destructive",
      });
      console.error("Error adding comment:", error);
    },
  });

  const updateCommentMutation = useRpcMutation({
    orgID: org.id,
    mutate: (input: GroceryListProposalAPI.UpdateComment) => input,
    onSuccess: () => {
      toast({
        title: "Comment Updated",
        description: "Your comment has been updated successfully.",
      });
      clearChanges();
      onRefetch();
      if (onCancelEdit) onCancelEdit();
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to update the comment. Please try again.",
        variant: "destructive",
      });
      console.error("Error updating comment:", error);
    },
  });

  const handleCommentSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (newComment.trim() || dev.unsavedChanges.length > 0) {
      const content = [
        ...(newComment.trim()
          ? [
              {
                _tag: "Markdown" as const,
                markdown: newComment.trim(),
              },
            ]
          : []),
        ...(dev.unsavedChanges.length > 0
          ? [
              {
                _tag: "Changes" as const,
                changes: dev.unsavedChanges,
              },
            ]
          : []),
      ];

      if (existingComment) {
        updateCommentMutation.mutate(
          new GroceryListProposalAPI.UpdateComment({
            proposalId,
            commentTs: existingComment.ts,
            content,
          }),
        );
      } else {
        createCommentMutation.mutate(
          new GroceryListProposalAPI.CreateComment({
            proposalId,
            content,
          }),
        );
      }
    }
  };

  const renderChanges = () => {
    if (dev.unsavedChanges.length === 0) {
      return null;
    }

    return (
      <div $="bg-gray-50 dark:bg-gray-700 p-3 rounded-md">
        <ul $="space-y-2">
          {dev.unsavedChanges.map((change, index) => (
            <li key={index} $="flex items-start">
              <RenderChangeIcon change={change} />
              <span $="ml-2 text-sm">
                {formatChange(change, currentState, originalItems)}
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <form
      ref={formRef}
      onSubmit={handleCommentSubmit}
      $="space-y-4 p-4 rounded-lg border border-gray-200 dark:border-gray-600 focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-transparent transition-all duration-200 ease-in-out"
    >
      <Textarea
        $
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
        placeholder="Write your comment here..."
        rows={3}
        className="focus:ring-0 focus:border-transparent"
      />
      {renderChanges()}
      <div $="flex justify-end space-x-2">
        {existingComment && (
          <Button $ type="button" variant="outline" onClick={onCancelEdit}>
            Cancel
          </Button>
        )}
        <Button
          $
          type="submit"
          disabled={!newComment.trim() && dev.unsavedChanges.length === 0}
        >
          {existingComment ? "Update Comment" : "Add Comment"}
        </Button>
      </div>
    </form>
  );
};
