import { UserNameChip } from "@/components/UserNameChip";
import { Button } from "@/components/ui/button";
import { Ta } from "@/components/ui/icons";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";
import { useGroceryList } from "@/contexts/GroceryListContext";
import { useRpcMutation } from "@/hooks/use-rpc-hooks";
import { useCurrentOrg } from "@/routes/$org._layout";
import {
  type GroceryListCommentContentKind,
  GroceryListProposalAPI,
  type GroceryListProposalID,
} from "@phosphor/server";
import type React from "react";
import { useState } from "react";
import { RenderChangeIcon, formatChange } from "./GroceryListChangeUtils";

interface GLProposalCommentProps {
  proposalId: GroceryListProposalID;
  comment: GroceryListProposalAPI.CommentInfo;
  onRefetch: () => void;
}

export const GLProposalComment: React.FC<GLProposalCommentProps> = ({
  proposalId,
  comment,
  onRefetch,
}) => {
  const { org } = useCurrentOrg();
  const { currentState, originalItems } = useGroceryList();
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState("");

  const updateCommentMutation = useRpcMutation({
    orgID: org.id,
    mutate: (input: GroceryListProposalAPI.UpdateComment) => input,
    onSuccess: () => {
      toast({
        title: "Comment Updated",
        description: "Your comment has been updated successfully.",
      });
      setIsEditing(false);
      onRefetch();
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to update the comment. Please try again.",
        variant: "destructive",
      });
      console.error("Error updating comment:", error);
    },
  });

  const handleEditClick = () => {
    setEditedContent(
      comment.content[0]._tag === "Markdown" ? comment.content[0].markdown : "",
    );
    setIsEditing(true);
  };

  const handleUpdateComment = () => {
    if (editedContent.trim()) {
      updateCommentMutation.mutate(
        new GroceryListProposalAPI.UpdateComment({
          proposalId,
          commentTs: comment.ts,
          content: [
            {
              _tag: "Markdown",
              markdown: editedContent.trim(),
            },
          ],
        }),
      );
    }
  };

  const handleDiscardChanges = () => {
    setIsEditing(false);
    setEditedContent("");
  };

  return (
    <div $="border-t pt-4">
      <div $="flex items-center justify-between mb-2">
        <UserNameChip userId={comment.authorUserId} />
        <div $="flex items-center space-x-2">
          <span $="text-xs text-gray-500">{comment.ts.toLocaleString()}</span>
          {!isEditing && (
            <Button $ variant="ghost" size="icon" onClick={handleEditClick}>
              <Ta.IconPencil $="w-4 h-4" />
            </Button>
          )}
        </div>
      </div>
      {isEditing ? (
        <div $="space-y-4">
          <Textarea
            $
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
          />
          <div $="flex justify-end space-x-2">
            <Button $ variant="outline" onClick={handleDiscardChanges}>
              Discard Changes
            </Button>
            <Button
              $
              onClick={handleUpdateComment}
              disabled={!editedContent.trim()}
            >
              Update Comment
            </Button>
          </div>
        </div>
      ) : (
        comment.content.map((content, index) => (
          <RenderCommentContent
            key={index}
            content={content}
            currentState={currentState}
            originalItems={originalItems}
          />
        ))
      )}
    </div>
  );
};

const RenderCommentContent: React.FC<{
  content: GroceryListCommentContentKind;
  currentState: ReturnType<typeof useGroceryList>["currentState"];
  originalItems: ReturnType<typeof useGroceryList>["originalItems"];
}> = ({ content, currentState, originalItems }) => {
  switch (content._tag) {
    case "Markdown":
      return <div $="prose dark:prose-invert text-sm">{content.markdown}</div>;
    case "Changes":
      return (
        <div $="bg-gray-50 dark:bg-gray-700 p-3 rounded-md">
          <h4 $="text-sm font-semibold mb-2">Changes:</h4>
          <ul $="space-y-2">
            {content.changes.map((change, index) => (
              <li key={index} $="flex items-start">
                <RenderChangeIcon change={change} />
                <span $="ml-2 text-sm">
                  {formatChange(change, currentState, originalItems)}
                </span>
              </li>
            ))}
          </ul>
        </div>
      );
  }
};
